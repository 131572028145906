// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#202d3d",
	"menuHover": "#6e010f",
	"subMenuBg": "#2e3a4c",
	"subMenuHover": "#6e010f",
	"sideBarWidth": "210px"
};
module.exports = exports;
