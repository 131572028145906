import request from '@manage/utils/request'

export function handleAddPlatformCertificateConfig(data) { //添加平台比赛证书配置
	return request({
		url: 'certificateTemplate/addPlatformCertificateConfig',
		method: 'post',
		data
	})
}



export function handleQueryPlatformCertificate() { //查询系统证书
	return request({
		url: 'certificateTemplate/queryPlatformCertificate',
		method: 'post',
	})
}

export function handleDeletePlatformCertificateConfifById(id) { //查询系统证书
	return request({
		url: 'certificateTemplate/deletePlatformCertificateConfifById',
		method: 'get',
		params: {
			id: id
		}
	})
}




export function handleQenerCertificateByMatchId(matchaId) { //生成证书
	return request({
		url: 'certificateTemplate/generCertificateByMatchId',
		method: 'post',
		params: {
			matchaId: matchaId
		}
	})
}


export function handleQueryCertificateTempateByMatchId(data) { //保存证书配置行数据
	return request({
		url: 'certificateTemplate/queryCertificateTempateByMatchId',
		method: 'post',
		data
	})
}

export function handleSaveCertificateConfigRow(data) { //保存证书配置行数据
	return request({
		url: 'certificateTemplate/saveCertificateConfigRow',
		method: 'post',
		data
	})
}

export function handleUploadCertificateTemplate(data) { //上传比赛证书
	return request({
		url: 'certificateTemplate/uploadCertificateTemplate',
		method: 'post',
		data
	})
}

export function handleResetCertificateTemplateEvent(data) { //重置证书模板
	return request({
		url: 'matchinfo/resetCertificateTemplateEvent',
		method: 'post',
		data
	})
}