import request from '@manage/utils/request'

export function handleGetSms(phone) { //获取验证码
  return request({
    url: 'assist/out/getValidateCode',
    method: 'post',
    params: {
      phone: phone
    }
  })
}


export function handleModifyUserPassword(data) { //修改用户密码
  return request({
    url: 'user/modifyUserPassword',
    method: 'post',
    data
  })
}


export function handlesignOut() { //退出
  return request({
    url: 'user/signOut',
    method: 'post'
  })
}
