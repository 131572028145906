<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

   <!-- <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img
            src="https://score2021.oss-cn-beijing.aliyuncs.com/user/src%3Dhttp___pic.51yuansu.com_pic2_cover_00_31_67_5810c4460e1ca_610.jpg%26refer%3Dhttp___pic.51yuansu.jpg"
            class="user-avatar"
          />
          {{ userInfo ? userInfo.name : "" }}
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">          
          <el-dropdown-item @click.native="editpass">
            <span>修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <modifypassword ref="password" /> -->
  </div>
</template>

<script>
import { Message } from "element-ui";
//import Cookies from 'js-cookie'

import { mapGetters } from "vuex";
import Breadcrumb from "@manage/components/Breadcrumb";
import Hamburger from "@manage/components/Hamburger";
import { handlesignOut } from "@manage/api/commonapi";
// import modifypassword from "@manage/components/modifypassword.vue";
export default {
  data() {
    return {
      userInfo: null,
    };
  },
  components: {
    Breadcrumb,
    Hamburger,
   // modifypassword,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar"]),
  },
  created() {
    const userInfo = sessionStorage.getItem("user");
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      handlesignOut().then((res) => {
        if (res.status == 200) {
          Message({
            message: res.msg,
            type: "success",
            duration: 5 * 1000,
          });
        } else {
          Message({
            message: res.msg,
            type: "error",
            duration: 5 * 1000,
          });
        }
        sessionStorage.clear();
        this.$router.push({
          path: "/manage/login",
        });
      });
    },
    editpass() {
      this.$refs.password.openDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
   
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      margin: 0px;
      min-width: 100px;
      height: 100%;
      .avatar-wrapper {
        // margin-top: 5px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          margin-right: 5px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
