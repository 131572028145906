
import {
	Message, Loading
} from 'element-ui'


import {
	handleQueryCertificateTempateByMatchId,
	handleSaveCertificateConfigRow,
	handleQenerCertificateByMatchId,
	handleUploadCertificateTemplate,
	handleResetCertificateTemplateEvent,
} from '@index/api/mymatchs/certmanage'
import matchtitle from '../../components/matchtitle';
export default {
	name: 'matchCert',
	components: { matchtitle },
	data() {
		return {
			tableData: [],
			certImg: "",
			matchId: sessionStorage.getItem("currMatchId"),
			havAuth: false
		}
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;//默认强制开启权限		
		},
		pageQuery() {
			handleQueryCertificateTempateByMatchId({ matchId: this.matchId }).then(res => {
				if (res.status == 200) {
					var tempArr = res.data.list;
					tempArr.forEach(elem => {
						elem.color = "rgba(" + elem.color + ")";
					});
					this.tableData = tempArr;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
					this.queryCert();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		queryCert() {
			handleQenerCertificateByMatchId(this.matchId).then(res => {
				this.certImg = res.data + "?r=" + Math.random();
			})
		},
		editRow(data, index, row, id) {
			//row.editFlag = '1';
			if (this.tableData && this.tableData.length > 0) {
				this.tableData.forEach(elem => {
					if (elem.id == id) {
						elem.editFlag = '1';
					} else {
						elem.editFlag = '0';
					}
				});
			}
			this.$set(this.tableData, index, row);
		},
		saveRow(row) {
			var color = row.color.replaceAll("rgba", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
			row.color = color;
			var loading = this.$loading({
				lock: true,
				text: '处理中......',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			handleSaveCertificateConfigRow(row).then(res => {
				this.pageQuery();
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loading.close();
				});
			});
		},
		cancelRow(data, index, row) {
			if (this.tableData && this.tableData.length > 0) {
				this.tableData.forEach(item => {
					item.editFlag = '0';
				});
			}
			this.$set(this.tableData, index, row);
		},
		uploadSuccess(response, file, fileList) {
			this.uploadCertificateTemplate(response);
			this.$refs.uploadref.clearFiles();

		},
		uploadCertificateTemplate(url) {

			handleUploadCertificateTemplate({ matchId: this.matchId, url: url }).then(res => {
				this.queryCert();

			})
		},
		exitEvent() {
			this.$router.push({
				path: "matchinfo",
				query: {
					menusShow: true
				}
			});
		},
		resetCertificateTemplateEvent() {
			var _this = this;
			this.$confirm('确定要重置证书信息?')
				.then(res0 => {
					handleResetCertificateTemplateEvent({ matchId: this.matchId }).then(res => {
						if (res.status == 200) {
							_this.$message({
								type: 'success',
								message: res.msg
							});
							_this.pageQuery();

						} else {
							Message({
								message: res.msg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					})
				});
		},
		beforeUpload(file) {
			const isLt2M = file.size / 1024 < 400;
			var isJPG =(file.type === 'image/jpeg'||file.type==='image/png');
			if(!isJPG){
				this.$message.error('图片只能是 JPG或者PNG 格式!');
				return false;
			}
			if (!isLt2M) {
				this.$message.error('图片大小不能超过400kb!');
				return false;
			}
			return true;
		}
	}
}