<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/manage/subhomeIndex">
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo"> -->
		<div class="logo-text">
			<div class="logo-box">记</div>
			<h1 class="sidebar-title">{{ title }} </h1>
		</div>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/manage/subhomeIndex">
        <!-- <img v-if="logo" :src="logo" class="sidebar-logo"> -->
		<div class="logo-text">
			<div class="logo-box">记</div>
			<h1 class="sidebar-title">{{ title }} </h1>
		</div>
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from '@/assets/logo-v.png'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '报名系统',
      logo: logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
	.logo-text{
		display: flex;
		justify-content:center;
		align-items: center;
		.logo-box{
			width:28px;
			height:28px;
			line-height:26px;
			border-radius:28px;
			font-size:22px;
			display:inline-block;
			color:#f33c27;
			background-color:#fff;
			font-weight: 600;
			font-family: 隶书;
			margin-right:5px;
		}
	}
	
    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
