import Cookies from 'js-cookie'

//const TokenKey = 'vue_admin_template_token'
const TokenKey =sessionStorage.getItem('token');
export function getToken() {
  return TokenKey;
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
