import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@manage/layout/index.vue';

Vue.use(VueRouter)

const routes = [{
		path: '/manage/login',
		name: 'login',
		component: () => import('@manage/views/login/index.vue'),
		hidden: true,
	},
	
	{
		path: '/manage/',
		name: 'homeIndex',
		component: Layout,
		redirect: '/manage/subhomeIndex',
		children: [{
			path: '/manage/subhomeIndex',
			name: 'subhomeIndex',
			component: () => import('@manage/views/bm/homeIndex/index.vue'),
			meta: {
				title: '系统主页',
				icon: 'el-icon-s-home'
			}
		}]
	},
	{
		path: '/manage/adminbm',
		name: 'adminbm',
		component: Layout,
		redirect: '/scorerLoginIndex',
		meta: {
			title: '赛事管理',
			icon: 'el-icon-trophy-1'
		},
		children: [{
				name: 'matchsManage',
				path: '/manage/matchsManage',
				component: () => import('@manage/views/bm/matchesmanage/index.vue'),
				meta: {
					title: '比赛管理'
				}
			}, {
				name: 'scorerLoginIndex',
				path: '/manage/scorerLoginIndex',
				component: () => import('@manage/views/bm/scorerLoginIndex/index.vue'),
				meta: {
					title: '记分长登陆页管理'
				}
			},
			{
				name: 'accoutauthmanage',
				path: '/manage/accoutauthmanage',
				component: () => import('@manage/views/bm/accoutauthmanage/index.vue'),
				meta: {
					title: '发布比赛权限管理'
				}
			},
			{
				name: 'playerManage',
				path: '/manage/playerManage',
				component: () => import('@manage/views/bm/playermanage/index.vue'),
				meta: {
					title: '选手管理'
				}
			},

		]
	},
	{
		path: '/manage/usermanage',
		name: 'usermanage',
		component: Layout,
		redirect: '/user',
		meta: {
			title: '用户管理',
			icon: 'el-icon-user-solid'
		},
		children: [{
				path: '/manage/useradminmanage',
				name: 'user',
				component: () => import('@manage/views/bm/useradminmanage/index.vue'),
				meta: {
					title: '管理员管理',
				},
			},
			{
				path: '/manage/user',
				name: 'user',
				component: () => import('@manage/views/bm/usermanage/index.vue'),
				meta: {
					title: '普通用户管理',

				},
			},
			{
				path: '/manage/user2',
				name: 'user2',
				component: () => import('@manage/views/bm/logoffusermanage/index.vue'),
				meta: {
					title: '注销账号',

				},
			}
		]
	},

	{
		path: '/manage/sysset',
		name: 'sysset',
		component: Layout,
		meta: {
			title: '系统设置',
			icon: 'el-icon-setting'
		},
		redirect: '/payconfig',
		children: [{
				path: '/manage/sysset/payconfig',
				name: 'payconfig',
				component: () => import('@manage/views/bm/set/payconfig/index.vue'),
				meta: {
					title: '支付设置',
				}
			},
			{
				path: '/payconfig2',
				name: 'payconfig2',
				meta: {
					title: '操作日志',
				}
			},
			{
				path: '/manage/sysset/certificatetemplate',
				name: 'certificatetemplate',
				component: () => import('@manage/views/bm/certificatetemplate/index.vue'),
				meta: {
					title: '证书模板',
				}
			}
		]
	},
	//   {
	//     path: '/manage/404',
	//     component: () => import('@index/views/error-page/404'),
	//     hidden: true
	//   },
	//   {
	//     path: '/manage/401',
	//     component: () => import('@index/views/error-page/401'),
	//     hidden: true
	//   }
	// {
	// 	name: 'matchinfo',
	// 	path: '/manage/matchinfo',
	// 	component: () => import('@index/views/mymatchs/matchs/matchinfo.vue'),
	// 	meta: {
	// 		keepAlive: false,
	// 		title: '比赛信息',
	// 		icon: 'el-icon-setting'
	// 	}
	// },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// 路由导航
router.beforeEach((to, from, next) => {
	const token = sessionStorage.getItem('token');		
	if (to.name !== 'login' && !token) {
		next({
			name: 'login'
		})
	} else {
		if (to.name == 'login' && token) {
			next({
				name: 'subhomeIndex'
			})
		}
		next()
	}
})

const createRouter = () => new VueRouter({
	// mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: routes
})


export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router