<template>

	<div :class="classObj" class="app-wrapper">
		<div class="app-header">
			<div class="app-header-title">我是记分长后台管理系统</div>
			<div class="user-info">
				<div class="right-menu">
					<el-dropdown class="avatar-container" trigger="click">
						<div class="avatar-wrapper">
							<img src="https://score2021.oss-cn-beijing.aliyuncs.com/user/src%3Dhttp___pic.51yuansu.com_pic2_cover_00_31_67_5810c4460e1ca_610.jpg%26refer%3Dhttp___pic.51yuansu.jpg"
								class="user-avatar" />
							欢迎您&nbsp;,<span class="user-name">{{ userInfo ? userInfo.name : "" }}</span>
							<i class="el-icon-caret-bottom"></i>
						</div>
						<el-dropdown-menu slot="dropdown" class="user-dropdown">
							<el-dropdown-item @click.native="editpass">
								<span>修改密码</span>
							</el-dropdown-item>
							<el-dropdown-item divided @click.native="logout">
								<span style="display: block">退出</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>

			</div>
			<div class="exit" @click="logout"><i class="fa fa-mail-forward"></i>退出系统</div>
		</div>
		<div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
		<div class="framework-main-content">
			<div class="framework-main-content-left">
				<sidebar class="sidebar-container" style="position: relative;" />
			</div>
			<div class="framework-main-content-right">
				<div class="main-container" style="margin-left: 0px;">
					<div :class="{'fixed-header':fixedHeader}">
						<!-- <navbar /> -->
					</div>
					<app-main />
				</div>
			</div>
		</div>


		<modifypassword ref="password" />
	</div>

</template>

<script>
	import {
		// Navbar,
		Sidebar,
		AppMain
	} from './components'
	import ResizeMixin from './mixin/ResizeHandler'
	import modifypassword from "@manage/components/modifypassword.vue";
	import {
		handlesignOut
	} from "@manage/api/commonapi";
	import {
		Message
	} from "element-ui";

	export default {
		name: 'Layout',
		components: {
			// Navbar,
			Sidebar,
			AppMain,
			modifypassword,
		},
		data() {
			return {
				userInfo: null,
			};
		},
		mixins: [ResizeMixin],
		computed: {
			sidebar() {
				return this.$store.state.app.sidebar
			},
			device() {
				return this.$store.state.app.device
			},
			fixedHeader() {
				return this.$store.state.settings.fixedHeader
			},
			classObj() {
				return {
					hideSidebar: !this.sidebar.opened,
					openSidebar: this.sidebar.opened,
					withoutAnimation: this.sidebar.withoutAnimation,
					mobile: this.device === 'mobile'
				}
			}
		},
		created() {
			const userInfo = sessionStorage.getItem("user");
			if (userInfo) {
				this.userInfo = JSON.parse(userInfo);
			}
		},
		methods: {
			handleClickOutside() {
				this.$store.dispatch('app/closeSideBar', {
					withoutAnimation: false
				})
			},
			async logout() {
				handlesignOut().then((res) => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: "success",
							duration: 5 * 1000,
						});
					} else {
						Message({
							message: res.msg,
							type: "error",
							duration: 5 * 1000,
						});
					}
					sessionStorage.clear();
					this.$router.push({
						path: "/manage/login",
					});
				});
			},
			editpass() {
				this.$refs.password.openDialog();
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@manage/styles/mixin.scss";
	@import "~@manage/styles/variables.scss";


	.framework-main-content {
		display: flex;
		height: calc(100vh - 60px);
		overflow: hidden;
	}

	.framework-main-content-left {
		width: 230px;
		height: calc(100vh - 60px);
		overflow: hidden;
	}

	.framework-main-content-right {
		width: 100%;
		height: calc(100vh - 60px);
		overflow: hidden;
	}


	.app-wrapper {
		@include clearfix;
		position: relative;
		height: 100%;
		width: 100%;
		// -webkit-user-select: none;
		overflow: hidden;

		&.mobile.openSidebar {
			position: fixed;
			top: 0;
		}
	}

	.drawer-bg {
		background: #000;
		opacity: 0.3;
		width: 100%;
		top: 0;
		height: 100%;
		position: absolute;
		z-index: 999;
	}

	.fixed-header {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9;
		width: calc(100% - #{$sideBarWidth});
		transition: width 0.28s;
	}

	.hideSidebar .fixed-header {
		width: calc(100% - 54px)
	}

	.mobile .fixed-header {
		width: 100%;
	}

	.app-header {
		height: 60px;
		line-height: 60px;
		color: white;
		background-color: white;
		// background-image: -webkit-gradient(linear, left top, right top, from(#63affd), to(#409eff));
		// background-image: linear-gradient(40deg, #63affd, #409EFF);
		background-color: #1e4683;
		display: flex;
	}

	.app-header-title {
		font-weight: bolder;
		font-size: 1.3rem;
		margin-left: 30px;
		min-width: 300px;

	}

	.user-info {
		width: 100%;
		text-align: right;
		margin-top: 7px;

	}

	.right-menu {
		float: right;
		height: 100%;
		padding-top: 7px;
		height: 60px;
		line-height: 60px;
		width: 150px;
		font-size: 0.8rem !important;

		.user-name {
			padding-left: 8px;

		}

		&:focus {
			outline: none;
		}

		.right-menu-item {
			display: inline-block;
			padding: 0 8px;
			height: 100%;

			color: #5a5e66;
			vertical-align: text-bottom;

			&.hover-effect {
				cursor: pointer;
				transition: background 0.3s;

				&:hover {
					background: rgba(0, 0, 0, 0.025);
				}
			}
		}

		.avatar-container {
			margin-right: 50px;
			margin: 0px;
			min-width: 100px;
			height: 100%;

			.avatar-wrapper {
				// margin-top: 5px;
				position: relative;
				display: flex;
				align-items: center;
				cursor: pointer;
				color: white !important;
				margin-top: -20px;
				font-size: 0.8rem;

				.user-avatar {
					width: 40px;
					height: 40px;
					border-radius: 20px;
					margin-right: 5px;

				}

				.el-icon-caret-bottom {
					cursor: pointer;
					position: absolute;
					right: -20px;
					top: 25px;
					font-size: 12px;
				}
			}
		}
	}

	.exit {
		// width: 200px;
		text-align: right;
		padding-right: 25px;
		cursor: pointer;
		font-size: 0.8rem;
		margin-left: 4rem;
		
	}

	.exit i {
		padding-right: 4px;
		// font-size: 16px;
	}

	::v-deep .el-dialog__header {
		background-color: #F2F2F2 !important;

	}

	::v-deep .el-dialog__footer {
		border-top: solid #D7D7D7 1px;
		padding: 12px !important;

	}

	::v-deep .el-input__inner {
		border-radius: 6px;
	}

	::v-deep .el-dialog {
		border-radius: 6px;
	}

	::v-deep .el-dialog__header {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	::v-deep .selected-row {
		background-color: rgb(234 245 255);
	}

	// ::v-deep .data-content {
	// 	margin-top: -5px;

	// }

	// ::v-deep .table-content {
	// 	height: calc(100vh - 198px) !important;



	// }

	// ::v-deep .el-table__body-wrapper {
	// 	height: calc(100vh - 250px) !important;
	// 	overflow-y: auto;

	// }

	::v-deep ::-webkit-scrollbar {
		width: 8px !important;
		background-color: gainsboro;

	}

	::v-deep ::-webkit-scrollbar-thumb {
		background-color: #7b7d83e3;
	}


	@media (max-width: 800px) {}
</style>