<!--修改密码-->
<template>
	<div>
		<el-dialog calss="modifyPasswordForm" title="修改登录密码" :visible.sync="modifyPasswordDialogVisible"
			:close-on-click-modal="false" destroy-on-close width="380px">
			<el-form :model="modifyPasswordForm" label-width="100px" :rules="modifyPasswordFormRules"
				style="padding: 0px 15px;">
				<el-form-item label="设置新密码" prop="password">
					<el-input size="small"  type="password" v-model="modifyPasswordForm.password" autocomplete="off" clearable
						show-password maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="repassword">
					<el-input size="small" type="password" v-model="modifyPasswordForm.repassword" autocomplete="off" clearable
						show-password maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="登录手机" prop="phone">
					<el-input  size="small" v-model="modifyPasswordForm.phone" disabled="disabled" clearable></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code" class="input-append">
					<el-input size="small" v-model="modifyPasswordForm.code" clearable>
						<template #append>
							<el-button size="small" type="primary" @click="getSmsCode()" :disabled="modifyPWDCodeButton.disabled">
								{{modifyPWDCodeButton.name}}
							</el-button>
						</template>
					</el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button size="small" @click="modifyPasswordDialogVisible = false">取 消</el-button>
					<el-button size="small" type="primary" @click="modifyPWDok">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script lang="ts">
	import {
		JSEncrypt
	} from "jsencrypt";
	import {
		handleGetSms,
		handleModifyUserPassword
	} from '@index/api/commonapi';
	import common from '@index/utils/common';
	export default {
		name: 'modifypassword',
		components: {

		},
		data() {
			return {
				modifyPasswordDialogVisible: false,
				modifyPasswordForm: {password:'',phone:'',code:'',repassword:'',smsId:''},
				modifyPWDCodeButton: {
					name: "获取验证码",
					disabled: false
				},
				modifyPasswordFormRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}],
					repassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},


			};
		},
		watch: {},
		created() {
			var loginUser = JSON.parse(sessionStorage.getItem('user'));
			this.modifyPasswordForm.phone = loginUser.phone;
		},
		mounted() {

		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			openDialog() {
				this.modifyPasswordDialogVisible = true;
			},
			getSmsCode() { //获取登陆验证码
				var phone = this.modifyPasswordForm.phone;
				let thisObj = this;
				this.requestSmsCode(phone, function (res) {
					thisObj.modifyPasswordForm.smsId = res;
					var time = 30;
					thisObj.modifyPWDCodeButton = {
						name: time + "s",
						disabled: true
					};

					var timer = setInterval(function () {
						if (--time >= 0) {
							thisObj.modifyPWDCodeButton = {
								name: time + "s",
								disabled: true
							}
						} else {
							thisObj.modifyPWDCodeButton = {
								name: "获取验证码",
								disabled: false
							};
							clearInterval(timer);
						}
					}, 1000)
				});
			},
			requestSmsCode(phone, callback) { //获取短信验证码
				try { //验证
					common.requiredValidate([{
						value: phone,
						tips: "电话不能为空!"
					}]);
				} catch (e) {
					this.$message({
						type: 'error',
						message: e
					});
					return;
				}

				handleGetSms(
					phone
				).then(res => {
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: '发送成功请注意查收!'
						});
						callback(res.data.smsId);
						//   this.form.smsId = res.data.smsId;
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}

				});

			},
			modifyPWDok() { //确认修改密码
				let encrypt = new JSEncrypt();
				var requestData = {
					...this.modifyPasswordForm
				};
				encrypt.setPublicKey(common.publicKey);

				if (this.modifyPasswordForm.password != this.modifyPasswordForm.repassword) {
					this.$message({
						type: 'error',
						message: '两次输入密码不一致!'
					});
					return;
				}
				if (!this.modifyPasswordForm.smsId) {
					this.$message({
						type: 'error',
						message: '请获取验证码!'
					});
					return;
				}
				requestData.password = encrypt.encrypt(this.modifyPasswordForm.password);
				requestData.repassword = encrypt.encrypt(this.modifyPasswordForm.repassword);
				this.loading = true;
				handleModifyUserPassword(requestData).then(res => {
					this.loading = false;
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.modifyPasswordDialogVisible = false;
						this.modifyPasswordForm = {};

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}

				});
			}

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.modular-title {
		height: 40px;
		line-height: 40px;
		font-weight: bolder;
		font-size: 16px;
	}

	.modifyPasswordForm {
		.is-disabled {
			background: #b52f31 !important;
			cursor: not-allowed !important;
			color: white !important;
			width: 132px;
		}

		.is-disabled:hover {
			background: #b52f31 !important;
			border-color: #b52f31;
			cursor: not-allowed !important;
			color: white !important;
			width: 132px;
		}

		.el-form-item {
			margin-bottom: 20px !important;
		}

		.el-dialog__body {
			padding: 0px 20px !important;
		}
	}

	::v-deep .input-append .el-input__inner {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}

	::v-deep .el-input__inner {
		border: 1px solid #dcdfe6 !important;
	}
	::v-deep .el-input-group__append, .el-input-group__prepend{
		border:1px solid #dcdfe6 !important;
	}
</style>